import Icon from './Icon';
import { ReactComponent as HeartEmpty } from '../../icons/heart-empty.svg';
import { ReactComponent as HeartSolid } from '../../icons/heart-solid.svg';

const Favorite = ({ favorited, onClick }) => {
  let icon;
  let type = favorited ? 'solid' : 'empty';

  if (!favorited) {
    icon = <HeartEmpty />;
  } else {
    icon = <HeartSolid />;
  }

  return (
    <div className="d-block text-right clickable">
      <Icon className={type} icon={icon} onClick={onClick} />
    </div>
  );
};

export default Favorite;
