import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../common/Icon';
import auth from '../../services/authService';
import logo from '../../images/mg_logo.png';
import NavBarItem from '../NavBarItem';
import { ReactComponent as PersonCircleIcon } from '../../icons/profile-circle.svg';
import { ReactComponent as SettingIcon } from '../../icons/settings.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { ReactComponent as SongsIcon } from '../../icons/playlist.svg';
import { ReactComponent as PeopleIcon } from '../../icons/group.svg';
import { ReactComponent as LogOutIcon } from '../../icons/log-out.svg';

import './NavBar.scss';

const NavBar = () => {
  const [user] = useState(auth.getCurrentUser());
  const dropdownRef = useRef();

  const handleNavBarItems = () => {
    // Hide nav each time nav-items are clicked (mobile)
    dropdownRef.current.className = 'collapse navbar-collapse';
  };

  if (!user) return null;

  return (
    <>
      <div className="color-bar"></div>
      <nav className="navbar navbar-expand-lg">
        <div className="container position-relative">
          <Link className="navbar-brand" to="/songs">
            <img id="logo" src={logo} alt="Migrupo logo" />
          </Link>

          <div className="notification-container">
            <button
              className="navbar-toggler position-relative"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div ref={dropdownRef} className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <NavBarItem label="Songs" icon={<SongsIcon />} path="/songs" handler={handleNavBarItems} />
              <NavBarItem
                label={user && user.teamName}
                icon={<PeopleIcon />}
                path="/myteam"
                handler={handleNavBarItems}
              />
              <NavBarItem label="Public Songs" icon={<SearchIcon />} path="/searchsongs" handler={handleNavBarItems} />
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="dropdown-menu"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <Icon icon={<PersonCircleIcon />} />
                  <span>{user && user.firstName}</span>
                </a>
                <div className="dropdown-menu" aria-labelledby="dropdown-menu">
                  <Link onClick={handleNavBarItems} className="dropdown-item" to="/settings">
                    <Icon icon={<SettingIcon />} />
                    <span className="dropdown-text">Settings</span>
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item" to="/signout">
                    <Icon icon={<LogOutIcon />} />
                    <span className="dropdown-text">Sign Out</span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
