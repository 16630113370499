import Icon from './Icon';
import { ReactComponent as EllipsisVertical } from '../../icons/more-vert.svg';
import { ReactComponent as CreateOutlineIcon } from '../../icons/plus-circle.svg';
import { ReactComponent as DeleteIcon } from '../../icons/xmark-circle-solid.svg';
import './Options.css';

const Options = ({ modalId, item, onDelete, onEdit, editId }) => {
  // modalId: the id of the modal to show for deleting a song or service
  let showOptions = true;
  if (!editId) {
    showOptions = true;
  } else {
    showOptions = false;
  }
  return (
    <div className="options">
      <ul className="navbar-nav">
        <li className="nav-item dropdown">
          {showOptions && (
            <a
              className="nav-link option-icon"
              href="/"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <Icon icon={<EllipsisVertical />} className="options-icon" />
            </a>
          )}
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <button onClick={() => onEdit(item)} className="dropdown-item" type="button">
              <Icon className="options__icon" icon={<CreateOutlineIcon />} />
              <span className="options__title">Edit</span>
            </button>
            <div className="dropdown-divider"></div>
            <button
              onClick={() => onDelete(item)}
              className="dropdown-item"
              type="button"
              data-toggle="modal"
              data-target={`#${modalId}`}
            >
              <span className="options__delete">
                <Icon className="options__icon" icon={<DeleteIcon />} />
                <span className="options__title">Delete</span>
              </span>
            </button>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Options;
