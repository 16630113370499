import Icon from '../../../../components/common/Icon';
import { ReactComponent as InfoIcon } from '../../../../icons/info-circle-solid.svg';

const SearchSongsNotFound = ({ searchQuery }) => {
  return (
    <div className="not-found">
      <Icon icon={<InfoIcon />} />
      <p>No Public Songs found for "{searchQuery}".</p>
      <p className="text-color">
        <strong>Be the first to add it and make it public!</strong>
      </p>
    </div>
  );
};

export default SearchSongsNotFound;
