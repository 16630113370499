import { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

class TableHeader extends Component {
  raiseSort = (path) => {
    const sortColumn = { ...this.props.sortColumn };
    if (sortColumn.path === path) {
      sortColumn.order = sortColumn.order === 'asc' ? 'desc' : 'asc';
    } else {
      sortColumn.path = path;
      sortColumn.order = 'asc';
    }
    this.props.onSort(sortColumn);
  };

  renderSortIcon = (column) => {
    const { sortColumn } = this.props;
    // if the current column is not the sortColumn don't render the icon
    if (column.path !== sortColumn.path) return null;
    // if the column is an action column (not a title)
    if (!column.label) return null;

    if (sortColumn.order === 'asc') return <Icon icon="caret-up-outline" />;
    if (sortColumn.order === 'desc') return <Icon icon="caret-down-outline" />;

    return null; // no sort
  };

  renderClasses = (column) => {
    if (column.label) return 'clickable';
    return '';
  };

  render() {
    const { columns } = this.props;
    return (
      <thead>
        <tr>
          {columns.map((column) => (
            <th
              className={this.renderClasses(column)}
              key={column.path || column.key}
              onClick={() => this.raiseSort(column.path)}
            >
              {column.label} {this.renderSortIcon(column)}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  sortColumn: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default TableHeader;
