import Icon from './Icon';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';

const PartEditControl = ({ part, onPartEdit }) => {
  return (
    <span className="clickable">
      <Icon icon={<EditIcon />} onClick={() => onPartEdit(part)} />
    </span>
  );
};

export default PartEditControl;
