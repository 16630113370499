import { NavLink } from 'react-router-dom';
import Icon from '../common/Icon';

const NavBarItem = ({ label, icon, path, handler }) => {
  return (
    <li className="nav-item">
      <NavLink onClick={handler} className="nav-link" to={path}>
        <Icon icon={icon} />
        {label}
      </NavLink>
    </li>
  );
};

export default NavBarItem;
