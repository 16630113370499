import { Component } from 'react';
import Icon from '../common/Icon';
import { ReactComponent as AddIcon } from '../../icons/plus-circle-solid.svg';
import { ReactComponent as CalendarIcon } from '../../icons/playlist-plus.svg';

class SongsMenu extends Component {
  render() {
    const { serviceCount } = this.props;
    return (
      <div className="songs-menu-container sticky">
        <div className="container">
          <ul className="songs-menu horizontal-menu">
            <li onClick={this.props.onNewSong} className="clickable">
              <Icon icon={<AddIcon />} />
              <span className="label"> New Song</span>
            </li>
            <span className={serviceCount() ? 'clickable' : 'no-action'}>
              <li onClick={serviceCount() ? this.props.onCreateService : () => {}}>
                <Icon icon={<CalendarIcon />} />
                <span className="label"> New Service </span>
                {serviceCount() && (
                  <span className="service-count">
                    <span>{serviceCount()}</span>
                  </span>
                )}
              </li>
            </span>
          </ul>
        </div>
      </div>
    );
  }
}

export default SongsMenu;
