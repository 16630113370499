import Icon from './Icon';
import { ReactComponent as CopyIcon } from '../../icons/copy.svg';

const PartDuplicateControl = ({ part, onPartDuplicate }) => {
  return (
    <span className="clickable">
      <Icon icon={<CopyIcon />} onClick={() => onPartDuplicate(part)} />
    </span>
  );
};

export default PartDuplicateControl;
