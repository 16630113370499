import Icon from '../common/Icon';
import { ReactComponent as MusicIcon } from '../../icons/album-list.svg';

function ServiceSong({ onDeleteSong, song }) {
  function renderSongCategoryClass(categoryName) {
    let baseClass = '';
    baseClass += categoryName === 'Praise' ? 'service-song__category-praise' : 'service-song__category-worship';

    return baseClass;
  }

  return (
    <li className="service-song">
      <div className={`service-song__left-container drag-handle ${renderSongCategoryClass(song.category.name)}`}>
        <div className="service-song__category-wrapper">
          <div className="service-song__icon">
            <Icon icon={<MusicIcon />} className={renderSongCategoryClass(song.category.name)} />
          </div>
          <div className="service-song__category">{song.category.name}</div>
        </div>
      </div>
      <div className="service-song__middle-container">
        <div className="service-song__info-wrapper">
          <div className="service-song__title text-ellipsis">{song.title}</div>
          <div className="service-song__artist text-ellipsis">{song.artist}</div>
        </div>
        <div className="service-song__actions-wrapper">
          <div onClick={() => onDeleteSong(song)} className="service-song__remove-btn">
            <span>Remove</span>
          </div>
        </div>
      </div>
      <div className="service-song__right-container">
        <div className="service-song__key-wrapper">{song.key}</div>

        <div className="service-song__bpm-wrapper">
          {song.bpm && (
            <div className="service-song__bpm">
              {song.bpm} <span className="service-song__bpm-text">BPM</span>
            </div>
          )}
        </div>
      </div>
    </li>
  );
}

export default ServiceSong;
