import Icon from "./Icon";
import { ReactComponent as XMark } from '../../icons/xmark-circle-solid.svg';


const PartDeleteControl = ({ onPartDelete, part, partIndex, disabled }) => {
  return (
    <span className={`btn-delete ${disabled ? "no-action" : "clickable"}`}>
      <Icon
        icon={<XMark />}
        onClick={() => !disabled && onPartDelete(part, partIndex)}
      />
    </span>
  );
};

export default PartDeleteControl;
