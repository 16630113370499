import Icon from '../common/Icon';
import './Modal.css';

const Modal = ({ modalId, btnText, icon, item, onDelete }) => {
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${modalId}Label`}>
              Confirm
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {item && (
              <span>
                <Icon icon={icon} /> {/* A song has an artist property, a service a date property */}
                {item.artist && <span>{`${item.title} - ${item.artist}`}</span>}
                {item.date && <span>{item.title}</span>}
              </span>
            )}
          </div>
          <div className="modal-footer">
            <button onClick={onDelete} type="button" className="btn btn-primary" data-dismiss="modal">
              {btnText}
            </button>
            <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
