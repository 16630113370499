import { useContext } from "react";
import ServiceContext from "../context/serviceContext";
import Icon from "../common/Icon";
import { ReactComponent as PlusCircle } from '../../icons/plus-circle.svg';
import { ReactComponent as PlusCircleSolid } from '../../icons/plus-circle-solid.svg';

const AddToService = ({ onClick, song }) => {
  const serviceContext = useContext(ServiceContext);

  const service = serviceContext.currentService;
  const songFound = service.find((s) => s._id === song._id);
  const icon = songFound ? <PlusCircleSolid /> : <PlusCircle />;

  return (
    <div className="d-block text-right clickable">
      <Icon icon={icon} onClick={onClick} />
    </div>
  );
};

export default AddToService;
