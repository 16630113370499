import { Fragment, useRef } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../../components/common/Icon';
import { ReactComponent as HelpIcon } from '../../../../icons/info-circle-solid.svg';

const TeamInvitation = ({ team, inviteLink, onCopyLink }) => {
  const inviteLinkRef = useRef();
  const teamIdRef = useRef();

  return (
    <>
      <button onClick={() => onCopyLink(inviteLinkRef)} type="button" className="btn btn-primary mb-2 btn-copy-invi">
        <span className="btn-copy-invi__text">Copy Invitation Link</span>
      </button>

      <p className="text-muted mb-0">
        <small>
          Copy this link and send it to your team mates to invite them to join
          {` ${team.name}`}.
        </small>
      </p>

      <span className="hidden" ref={inviteLinkRef}>
        {inviteLink}
      </span>

      <small className="team-id d-block">
        Team ID: <span ref={teamIdRef}>{team._id}</span>
        <span className="clickable" data-tip data-for="teamIdTip">
          <Link to="/faq#teamId">
            <Icon icon={<HelpIcon />} />
          </Link>
        </span>
      </small>
      <button
        onClick={() => onCopyLink(teamIdRef)}
        type="button"
        className="btn btn-light btn-sm btn-copy-id mt-2 mb-4"
      >
        <span className="btn-copy-id__text">Copy Team ID</span>
      </button>
    </>
  );
};

export default TeamInvitation;
