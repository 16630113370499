import { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { ToastContainer } from 'react-toastify';
import { Route, Redirect, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Main from './pages/Main';
import Team from './pages/Team';
import View from './pages/View/';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import SignOut from './components/auth/SignOut';
import ResetPassword from './components/auth/ResetPassword';
import NewPassword from './components/auth/ResetPassword/components/NewPassword';
import NavBar from './components/NavBar';
import Settings from './pages/Settings';
import SongForm from './components/SongForm';
import NotFound from './components/NotFound';
import ProtectedRoute from './components/common/ProtectedRoute';
import Faq from './components/Faq';
import PublicSongs from './pages/PublicSongs';
import theme from './utils/theme';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

if (process.env.NODE_ENV !== 'development')
  Sentry.init({
    dsn: 'https://5dbb126a850544cb8b45949eda9150e8@o418916.ingest.sentry.io/5325307',
  });

class App extends Component {
  componentDidMount() {
    theme.applyTheme();
  }

  render() {
    return (
      <>
        <ToastContainer />
        <NavBar />
        <Switch>
          <ProtectedRoute path="/view/:service/:id" component={View} />
          <ProtectedRoute path="/view/:id" component={View} />
          <Route path="/signup/:teamId" component={SignUp} />
          <Route path="/signup" component={SignUp} />
          <Route path="/signin" component={SignIn} />
          <Route path="/signout" component={SignOut} />
          <Route path="/resetpassword/:userId/:token" component={NewPassword} />
          <Route path="/resetpassword" component={ResetPassword} />
          <Route path="/faq" component={Faq} />
          <ProtectedRoute path="/songs/:id" component={SongForm} />
          <ProtectedRoute path="/songs" component={Main} />
          <ProtectedRoute path="/myteam" component={Team} />
          <ProtectedRoute path="/settings" component={Settings} />
          <ProtectedRoute path="/searchsongs" component={PublicSongs} />
          <Route path="/not-found" component={NotFound} />
          <Route path="/" exact component={SignIn} />
          {/* <Redirect from="/" exact to="/songs" /> */}
          <Redirect to="/not-found" />
        </Switch>
      </>
    );
  }
}

export default App;
