import Joi from 'joi-browser';
import { getCategories } from '../../services/fakeCategoryService';
import sanitizeHtml from 'sanitize-html';
import _ from 'lodash';
import Icon from '../common/Icon';
import songService from '../../services/songService';
import ToggleSwitch from '../common/ToggleSwitch';
import songKeys from '../../data/keys.json';
import songParts from '../../data/songParts.json';
import Form from '../common/Form';
import SongParts from '../SongParts';
import auth from '../../services/authService';
import { scrollTop } from '../../utils/utilities';
import './SongForm.css';
import { toast } from 'react-toastify';
import Footer from '../common/Footer';
import { ReactComponent as PeopleIcon } from '../../icons/group.svg';

class SongForm extends Form {
  state = {
    data: {
      title: '',
      artist: '',
      key: '',
      bpm: '',
      categoryId: '',
      youtube: '',
      favorite: false,
      parts: [],
      teamId: '',
      currentPart: 'Intro',
      currentLyrics: '',
      partNumber: 1,
      isPublic: true,
    },
    errors: {},
    categories: [],
    keys: [],
    songParts: [],
    editPartId: null,
    savingSong: false,
  };

  schema = {
    _id: Joi.string(),
    title: Joi.string().max(60).required().label('Title'),
    artist: Joi.string().max(50).required().label('Artist'),
    key: Joi.string().required().min(1).max(7).label('Key'),
    bpm: Joi.number().min(0).max(1000).allow('').label('BPM'),
    categoryId: Joi.string().required().label('Category'),
    youtube: Joi.string().max(100).allow('').label('YouTube'),
    favorite: Joi.label('Favorite'),
    parts: Joi.array().min(1).required().label('Parts'),
    teamId: Joi.string(),
    currentLyrics: Joi.label('Lyrics and Chords'),
    currentPart: Joi.label('Parts'),
    partNumber: Joi.label('Number'),
    isPublic: Joi.boolean().required(),
    isCopy: Joi.boolean(),
    originalTeam: Joi.object(),
  };

  async componentDidMount() {
    this.populateCategories();
    this.populateSongParts();
    this.populateSongKeys();
    await this.populateSong();
    scrollTop();
  }

  componentWillUnmount() {
    if (this.state.savingSong) this.setState({ savingSong: false });
  }

  populateCategories() {
    this.setState({ categories: getCategories() });
  }

  populateSongParts() {
    this.setState({ songParts: songParts });
  }

  async populateSong() {
    try {
      const songId = this.props.match.params.id;
      if (songId === 'new') {
        // New song: set team id
        const data = { ...this.state.data };
        const { teamId } = auth.getCurrentUser();
        data.teamId = teamId;
        this.setState({ data });
        return;
      }
      // Existing song
      const song = await songService.getSong(songId);

      // Load duplicate parts
      song.parts = songService.loadDuplicateParts(song);

      // Deny access to edit public songs from another teams
      if (song.team._id !== auth.getCurrentUser().teamId) throw new Error('Forbidden');

      this.setState({ data: this.mapToViewModel(song) });
    } catch (ex) {
      this.props.history.replace('/not-found');
    }
  }

  populateSongKeys() {
    this.setState({ keys: songKeys });
  }

  mapToViewModel(song) {
    // bpm and youtube are optional, map them when undefined
    return {
      _id: song._id,
      title: song.title,
      artist: song.artist,
      key: song.key,
      bpm: song.bpm || '',
      categoryId: song.category._id,
      youtube: song.youtube || '',
      favorite: song.favorite,
      parts: song.parts,
      teamId: song.team._id,
      currentPart: 'Intro',
      currentLyrics: '',
      partNumber: 1,
      isPublic: song.isPublic || false,
      isCopy: song.isCopy,
      originalTeam: song.originalTeam,
    };
  }

  handleAddPart = () => {
    let { currentLyrics, currentPart, partNumber } = this.state.data;

    // Sanitize html in lyrics
    currentLyrics = sanitizeHtml(currentLyrics, {
      allowedTags: [],
      allowedAttributes: {},
    });

    const part = { currentLyrics, partNumber };

    // Validate
    const errors = this.validatePart(part);
    this.setState({ errors: errors || {} });

    if (errors) return;

    this.addPart(currentPart, currentLyrics.trimEnd());
  };

  addPart = (name, lyrics) => {
    let { data, editPartId } = this.state;
    const { parts, partNumber } = data;

    if (editPartId) {
      // Edit part
      const part = parts.find((p) => p.id === editPartId);

      part.name = name;
      part.number = partNumber;
      part.lyrics = lyrics;

      // Apply changes to duplicates, if any
      if (part.hasDuplicates) {
        parts.forEach((p) => {
          if (p.isDuplicate && p.duplicateId === part.id) {
            p.name = name;
            p.number = partNumber;
            p.lyrics = lyrics;
          }
        });
      }

      // Editing a duplicate part
      if (part.isDuplicate) {
        const originalPart = parts.find((p) => p.id === part.duplicateId);
        // If there are any changes, it becomes an original part
        if (
          part.name !== originalPart.name ||
          part.number !== originalPart.number ||
          part.lyrics !== originalPart.lyrics
        ) {
          delete part.isDuplicate;
          delete part.duplicateId;

          // Check if the original part has more duplicates
          const duplicates = parts.filter((p) => p.duplicateId === originalPart.id);

          if (duplicates.length === 0) {
            const index = parts.findIndex((p) => p.id === originalPart.id);
            parts[index].hasDuplicates = false;
          }
        }
      }

      const index = parts.findIndex((p) => p.id === part.id);
      parts.splice(index, 1, part);
    } else {
      // Add part
      parts.push({
        id: Date.now().toString(),
        name,
        number: partNumber,
        lyrics,
      });
    }

    editPartId = null; // reset editPartId
    data.currentLyrics = ''; // reset currentLyrics

    data.parts = parts;

    this.setState({ data, editPartId });

    // update partNumber for current partName
    let partCount = this.getPartNumber();
    this.updatePartNumber(partCount);
  };

  getPartNumber = () => {
    const { parts, currentPart } = this.state.data;
    const partCount = parts.filter((part) => part.name === currentPart);

    let maxPartNumber = 0;

    partCount.forEach((part) => {
      if (part.number > maxPartNumber) {
        maxPartNumber = parseInt(part.number);
      }
    });

    return maxPartNumber;
  };

  updatePartNumber = (partCount) => {
    const { data } = this.state;
    data.partNumber = parseInt(partCount) + 1;
    this.setState({ data: data });
  };

  handlePartMove = (part, direction) => {
    const { data } = this.state;
    const { parts } = data;

    const currentIndex = parts.indexOf(part);
    const destination = direction === 'down' ? currentIndex + 1 : currentIndex - 1;

    parts.splice(currentIndex, 1);
    parts.splice(destination, 0, part);

    data.parts = [...parts];
    this.setState({ data });
  };

  sortNoteParts = (parts) => {
    let notePartCount = 0;

    parts.forEach((part) => {
      if (part.name === 'Note') {
        notePartCount += 1;
        part.number = notePartCount;
      }
    });
  };

  handlePartSort = (oldIndex, newIndex) => {
    if (oldIndex === newIndex) return;

    const { data } = this.state;
    const { parts } = data;

    // move part
    parts.splice(newIndex, 0, parts.splice(oldIndex, 1)[0]);

    // sort note parts
    this.sortNoteParts(parts);

    data.parts = [...parts];
    this.setState({ data });
  };

  handlePartChange = ({ currentTarget: input }) => {
    let { data, editPartId } = this.state;
    // set input to state manually
    data[input.name] = input.value;
    data.partNumber = this.getPartNumber() + 1;
    this.setState({ data, editPartId });
  };

  handlePartDelete = (part, partIndex) => {
    const { data } = this.state;
    const prevData = _.cloneDeep(data);
    const { parts, currentPart } = data;

    // Delete part
    parts.splice(partIndex, 1);

    // Check if the original part has more duplicates
    if (part.isDuplicate) {
      const duplicates = parts.filter((p) => p.duplicateId === part.duplicateId);

      if (duplicates.length === 0) {
        const index = parts.findIndex((p) => p.id === part.duplicateId);
        parts[index].hasDuplicates = false;
      }
    }

    // Update partNumber
    if (part.name === currentPart) {
      const partCount = this.getPartNumber();
      this.updatePartNumber(partCount);
    }

    // sort note parts
    this.sortNoteParts(parts);

    data.parts = parts;

    this.setState({ data });

    this.showUndoToast(part, 'deleted', prevData);
  };

  handlePartDuplicate = (part) => {
    const { data } = this.state;
    const prevData = _.cloneDeep(data);
    const parts = [...data.parts];

    // Create duplicate
    const duplicate = {
      isDuplicate: true,
      duplicateId: part.id,
      id: Date.now().toString(),
      name: part.name,
      number: part.number,
      lyrics: part.lyrics,
    };
    parts.push(duplicate);

    // Mark original part
    const index = parts.findIndex((p) => p.id === part.id);
    parts[index].hasDuplicates = true;

    data.parts = parts;
    this.setState({ data });

    this.showUndoToast(part, 'duplicated', prevData);
  };

  showUndoToast = (part, message, prevData) => {
    const resetState = () => {
      this.setState({ data: prevData });
      toast.info('Action undone.', { hideProgressBar: true });
    };

    const undo = () => {
      return (
        <div className="undo-toast">
          <div>{`${part.name} ${part.number} ${message}.`}</div>
          <div className="undo-toast__undo" onClick={resetState}>
            Undo
          </div>
        </div>
      );
    };

    toast.info(undo);
  };

  handlePartEdit = (part) => {
    let { data, editPartId } = this.state;
    data.currentPart = part.name;
    data.partNumber = part.number;
    data.currentLyrics = part.lyrics;
    editPartId = part.id;
    this.setState({ data, editPartId });
  };

  renderAddButtonText() {
    const { editPartId } = this.state;
    let text = editPartId ? 'Update Part' : `Add Part`;
    return text;
  }

  handleIsPublic = () => {
    const { data } = this.state;
    data.isPublic = !data.isPublic;
    this.setState({ data });
  };

  doSubmit = async () => {
    try {
      this.setState({ savingSong: true });

      await songService.saveSong(this.state.data);

      // Redirect the user to where they came from
      const { state } = this.props.location;
      if (state) {
        // this.props.history.push(state.from);
        this.props.history.goBack();
      } else {
        this.props.history.push('/songs');
        scrollTop();
      }
    } catch (ex) {
      this.setState({ savingSong: false });
    }
  };

  renderToggleSwitch = (isPublic, isCopy, originalTeam) => {
    if (!isCopy) {
      return (
        <ToggleSwitch
          name="isPublic"
          value={isPublic}
          checked={isPublic}
          label="Public"
          onChange={this.handleIsPublic}
          helpPath="public-songs"
        />
      );
    } else {
      return (
        <>
          <span className="team-icon">
            <Icon icon={<PeopleIcon />} />
          </span>
          <small>{` Published by ${originalTeam.name}`}</small>
          <br />
          <small>This song can't be set to public.</small>
        </>
      );
    }
  };

  render() {
    const { categories, keys, songParts, editPartId, savingSong } = this.state;
    const { parts, isPublic, isCopy, originalTeam } = this.state.data;

    return (
      <>
        <div className="container">
          <form onSubmit={this.handleSubmit} className="song-form">
            <div className="row">
              <div className="col-lg-4">
                <p className="song-form__title">Song Info</p>
                <div className="mb-3">{this.renderToggleSwitch(isPublic, isCopy, originalTeam)}</div>
                {this.renderInput('title', 'Title', 'text')}
                {this.renderInput('artist', 'Artist', 'text')}
                <div className="song-form__input-group">
                  {this.renderSelect('key', 'Key', keys)}
                  {this.renderInput('bpm', 'BPM', 'number')}
                </div>
                {this.renderSelect('categoryId', 'Categories', categories)}
                {this.renderInput('youtube', 'YouTube', 'text')}
              </div>

              <div className="col-lg-5">
                <p className="song-form__title">Lyrics and Chords</p>
                <div className="form-row">
                  <div className="col-6">{this.renderSelect('currentPart', '', songParts, this.handlePartChange)}</div>
                  <div className="col-2">{this.renderInputNumber('partNumber', this.setInputToState)}</div>
                  <div className="col-4">
                    <button onClick={this.handleAddPart} type="button" className="btn btn-primary max-width">
                      <span className="btn__text">{this.renderAddButtonText()}</span>
                    </button>
                  </div>
                </div>
                {this.renderTextarea('currentLyrics', this.setInputToState)}
              </div>

              <div className="col-lg-3">
                <p className="song-form__title">Parts</p>
                <SongParts
                  editPartId={editPartId}
                  parts={parts}
                  onPartMove={this.handlePartMove}
                  onPartDelete={this.handlePartDelete}
                  onPartDuplicate={this.handlePartDuplicate}
                  onPartEdit={this.handlePartEdit}
                  onPartSort={this.handlePartSort}
                />
              </div>
            </div>
            {this.renderButton('Save', savingSong, 'song-form__submit-btn')}
          </form>
        </div>
        <Footer />
      </>
    );
  }
}

export default SongForm;
