import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import create from 'zustand';
import auth from '../../../../services/authService';
import Icon from '../../../../components/common/Icon';
import ViewServiceBar from '../ViewServiceBar/ViewServiceBar';
import ViewSongPlugins from '../ViewSongPlugins';
import { ReactComponent as SparkIcon } from '../../../../icons/sparks-solid.svg';
import { ReactComponent as CreateIcon } from '../../../../icons/plus-circle.svg';
import { ReactComponent as YouTubeIcon } from '../../../../icons/youtube.svg';

export const useViewHeaderStore = create((set) => ({
  songKey: '',
  setSongKey: (songKey) => set({ songKey }),
}));

const ViewHeader = ({
  song,
  hideChords,
  setHideChords,
  service,
  onPrevious,
  onNext,
  firstSong,
  lastSong,
  showPlugins,
  handleShowPlugins,
}) => {
  const songKey = useViewHeaderStore((state) => state.songKey);
  const setSongKey = useViewHeaderStore((state) => state.setSongKey);

  useEffect(() => {
    setSongKey(song.key);
  }, [song, setSongKey]);

  function renderSongTitle(song) {
    const user = auth.getCurrentUser();

    // create a link to edit the song
    if (user && user.isAdmin && user.teamId === song.team._id)
      return (
        <>
          {song.title}{' '}
          <Link
            to={{
              pathname: `/songs/${song._id}`,
              state: { from: `/view/${song._id}` },
            }}
          >
            <Icon icon={<CreateIcon />} />{' '}
          </Link>
        </>
      );

    return song.title;
  }

  return (
    <>
      <div className="view-header">
        <p className="song-title">{renderSongTitle(song)}</p>
        <p className="song-artist">{song.artist}</p>
        <div className="song-details-container">
          <div className="song-info">
            <span className="song-key">
              <span>Key:</span> {songKey}
            </span>
            {song.bpm && (
              <span className="song-bpm">
                <span>BPM:</span> {song.bpm}
              </span>
            )}
            {song.youtube && (
              <a className="youtube" href={song.youtube} target="blank">
                <Icon icon={<YouTubeIcon />} /> <span>YouTube</span>
              </a>
            )}
          </div>

          <div onClick={handleShowPlugins} className="plugins" title="Plugins">
            <Icon icon={<SparkIcon />} />
          </div>
        </div>
      </div>

      <span className="header-line"></span>

      {showPlugins && <ViewSongPlugins hideChords={hideChords} setHideChords={setHideChords} />}

      {service && (
        <ViewServiceBar
          onPrevious={onPrevious}
          onNext={onNext}
          firstSong={firstSong}
          lastSong={lastSong}
          service={service}
        />
      )}
    </>
  );
};

export default ViewHeader;
