import { useEffect, useState } from 'react';
import { setDocTitle } from '../../utils/utilities';
import Icon from '../../components/common/Icon';
import songService from '../../services/songService';
import SearchBox from '../../components/common/SearchBox';
import SearchSongsResult from './components/PublicSongsResult';
import SearchSongsNotFound from './components/PublicSongsNotFound';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';

import './PublicSongs.css';

const SearchSongs = () => {
  const [songs, setSongs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    setDocTitle('Public Songs');
  });

  const handleSearch = async (query) => {
    setSearchQuery(query);
    setSongs([]);
    setNoResults(false);

    if (query.length > 3) {
      try {
        const { data: songs } = await songService.getSearchSongs(query);

        if (songs.length === 0) setNoResults(true);

        setSongs(songs);
      } catch (ex) {}
    }
  };

  const handleClearInput = () => {
    setSearchQuery('');
    setSongs([]);
    setNoResults(false);
  };

  return (
    <div className="container">
      <div className="search-songs">
        <h2 className="title">
          <Icon icon={<SearchIcon />} />
          Public Songs
        </h2>
        <p className="sub-title">Search public songs from other teams!</p>
        <SearchBox
          value={searchQuery}
          label="Type a song title"
          onChange={handleSearch}
          onClearInput={handleClearInput}
        />
        {songs.length > 0 && (
          <div className="song-count">
            <p>
              {songs.length} {songs.length === 1 ? 'song' : 'songs'} found
            </p>
          </div>
        )}
        {noResults && <SearchSongsNotFound searchQuery={searchQuery} />}
        {songs.length > 0 && <SearchSongsResult songs={songs} />}
      </div>
    </div>
  );
};

export default SearchSongs;
