import Service from '../Service';
import NoContent from '../common/NoContent';
import Title from '../common/Title';
import { ReactComponent as CreateServiceIcon } from '../../icons/playlist-play.svg';
import './Services.scss';

const Services = ({ services, editServiceId, onViewService, onEditService, onDeleteService, loading }) => {
  const renderServices = () => {
    if (services.length !== 0)
      return (
        <ul className="services-list">
          {services.map((service) => (
            <Service
              key={service._id}
              service={service}
              onViewService={onViewService}
              onDelete={onDeleteService}
              onEdit={onEditService}
              editId={editServiceId}
            />
          ))}
        </ul>
      );
    else return <NoContent loading={loading} message="No upcoming services" />;
  };

  return (
    <div className="services">
      <Title name="Services" icon={<CreateServiceIcon />} />
      {renderServices()}
    </div>
  );
};

export default Services;
