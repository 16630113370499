import Icon from './Icon';
import { ReactComponent as Play } from '../../icons/play-solid.svg';

const ViewServiceBtn = ({ onViewService, service }) => {
  return (
    <span className="clickable view-service-control">
      <Icon className="solid play-icon" icon={<Play />} onClick={() => onViewService(service)} />
    </span>
  );
};

export default ViewServiceBtn;
